/* Dark Mode Styles */
.references-container {
    text-transform: uppercase;
    color: #fff; /* White text for readability */
    text-align: center;
    width: 95%;
    margin: auto;
    border-radius: 15px;
    margin-top: 80px;
    margin-bottom: 80px;
}

.references-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 20px;
    margin-bottom: 20px;
}

.reference-item {
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    background: rgba(255, 255, 255, 0.1);
}

.reference-info {
    text-align: left;
}

.reference-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 15px;
}

.reference-name {
    font-size: 18px;
    font-weight: bold;
}

.reference-position,
.reference-contact {
    font-size: 14px;
    color: #bbb;
}

.reference-details {
    list-style: none;
    padding: 0;
}

.reference-details li {
    font-size: 14px;
    color: #ddd;
    margin-bottom: 5px;
}

/* Ensure the reference details list has bullet points */
.reference-details {
    list-style-type: circle; /* Ensures bullet points are displayed */
    margin-left: 20px; /* Adds some indentation for the bullet points */
    padding-left: 10px; /* Adds padding for better spacing */
}

.reference-details li {
    margin-bottom: 10px; /* Optional: adds spacing between each list item */
}


.bullet {
    color: #007bff;
    margin-right: 5px;
}

.load-more-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    margin-top: 20px;
}

.load-more-button:hover {
    background-color: #45a049;
}

/* Photo Grid Styles */
.photo-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 40px;
}

.photo-item {
    background-color: #1f1f1f;
    border-radius: 8px;
    overflow: hidden;
}

.photo-img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}
