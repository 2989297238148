.experience-container {
    margin: 20px auto;
    margin-top: 80px;
    margin-bottom: 80px;
    max-width: 95%;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    color: #fff;  /* Light text color */
    text-transform: uppercase;
}

.experience-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.experience-item {
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    margin-bottom: 20px;
    padding: 50px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.experience-details {
    display: flex;
    align-items: center;
}

.experience-logo-container {
    flex: 0 0 50px;
    margin-right: 20px;
}

.experience-logo {
    width: 80px;
    height: 80px;
    border-radius: 15px;
    margin-bottom: 30px;
    object-fit: contain;
}

.experience-info {
    flex: 1;
}

.experience-role {
    font-size: 1.3rem;
    font-weight: 600;
    margin: 0;
    color: #f1f1f1;  /* Light color for titles */
}

.experience-company {
    font-size: 1.1rem;
    color: #bbb;  /* Light gray color for company name */
    margin: 5px 0;
}

.experience-dates {
    font-size: 0.9rem;
    color: #ccc;  /* Light gray color for dates */
    margin: 5px 0 15px 0;
}

.job-details {
    padding-left: 20px;
    margin-top: 20px;
}

.job-details li {
    font-size: 1rem;
    color: #ddd;  /* Lighter gray for job details */
    margin-bottom: 8px;
    line-height: 1.5;
}

.bullet {
    font-size: 1.2rem;
    color: #007bff;  /* Blue color for bullets */
    margin-right: 5px;
}
