.education-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 20px auto;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    background: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    margin-top: 80px;
    margin-bottom: 80px;
    max-width: 92%;
    transition: transform 0.3s ease-in-out;
    text-transform: uppercase;
}

.education-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
}

.education-logo {
    width: 80px;
    height: auto;
    border-radius: 10px;
    flex-shrink: 0;
}

.education-details {
    flex: 1;
    min-width: 200px;
}

.education-title {
    font-size: 1.8rem;
    margin: 0;
    color: #ffffff;
    font-weight: bold;
}

.education-subtitle {
    font-size: 1.3rem;
    margin: 8px 0;
    color: #a6a6a6;
}

.education-institution {
    font-size: 1rem;
    margin: 0;
    color: #828282;
    font-style: italic;
}

.education-dates {
    font-size: 0.9rem;
    color: #666666;
    margin-top: 5px;
}

.education-honours {
    font-style: italic;
    font-weight: bold;
    color: #2E8B57;
    /* Optional green for distinction */
}

.certificate-img {
    width: 500px;
    border-radius: 15px;
    margin-left: 100px;
    max-width: 100%;
    height: auto;
}


/* Responsive adjustments */
@media (max-width: 1024px) {
    .education-container {
        padding: 15px;
    }

    .education-content {
        flex-direction: column;
        gap: 15px;
        margin-top: 100px;
    }

    .education-logo {
        width: 70px;
    }

    .education-title {
        font-size: 1.6rem;
    }

    .education-subtitle {
        font-size: 1.1rem;
    }

    .education-institution {
        font-size: 0.95rem;
    }

    .education-dates {
        font-size: 0.85rem;
    }
}

@media (max-width: 1200px) {
    .education-container {
        flex-direction: column;
        padding: 15px;
    }

    .education-content {
        flex-direction: column;
        gap: 20px;
    }

    .education-logo {
        width: 60px;
    }

    .education-title {
        font-size: 1.4rem;
    }

    .education-subtitle {
        font-size: 1rem;
    }

    .education-institution {
        font-size: 0.85rem;
    }

    .education-dates {
        font-size: 0.75rem;
    }

    .certificate-img {
        margin-left: 0;
        width: 100%;
        max-width: 70%;
        margin: auto;
        margin-top: 50px;
        margin-bottom: 50px;
    }
}

@media (max-width: 480px) {
    .education-container {
        padding: 10px;
    }

    .education-title {
        font-size: 1.2rem;
    }

    .education-subtitle {
        font-size: 0.9rem;
    }

    .education-institution {
        font-size: 0.8rem;
    }

    .education-dates {
        font-size: 0.7rem;
    }

    .certificate-img {
        width: 90%;
    }
}