div#headerContainer
{
    width: 100%;
    position: fixed;
    z-index: 1000;
}
header.header {
   
    /* Keeps it above other elements */
    padding: 20px 50px;
    /* Adjust padding for a cleaner look */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.2);
    /* Semi-transparent background */
    backdrop-filter: blur(10px);
    /* Frosted glass effect */
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    /* Adds subtle shadow for depth */
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.3); */
    /* Subtle border */
}

a#logo {
    text-decoration: none;
    color: white;
    font-size: 25px;
}

@media (max-width: 800px) {

    a#logo {
        font-size: 15px;
    }
    header.header {
        padding: 20px;
    }
}

@media (max-width: 400px) {
    header.header {
        padding: 20px;
    }
}