.contact-container {
    text-align: center;
    padding: 20px; /* Increased padding for more space inside the container */
    background: rgba(255, 255, 255, 0.1);
    color: #f9f9f9;
    max-width:80%; /* Reduced max-width to allow more space on the sides */
    margin: auto;
    margin-top: 80px;
    margin-bottom: 80px;
    border-radius: 15px;
    text-transform: uppercase;
    padding-left: 30px;  /* Increased left padding for more space */
    padding-right: 30px; /* Increased right padding for more space */
}

.contact-title {
    font-size: 2rem;
    font-weight: bold;
    color: #f9f9f9;  
}

.contact-description {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    color: #ddd;  
}

.contact-links {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap; 
    justify-content: space-evenly; 
}

.contact-link {
    text-decoration: none;
    color: #f9f9f9; 
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: color 0.3s;
    margin: 10px;
}

.contact-icon {
    width: 30px;
    height: 30px;
}

/* Responsive styles */
@media (max-width: 768px) {
    .contact-title {
        font-size: 1.5rem;
    }

    .contact-description {
        font-size: 0.9rem; 
        margin-bottom: 1rem;  
    }

    .contact-links {
        gap: 1rem; 
        justify-content: center;  
    }

    .contact-link {
        font-size: 1rem; 
    }
}

@media (max-width: 480px) {
    .contact-title {
        font-size: 1.2rem; 
    }

    .contact-description {
        font-size: 0.85rem; 
    }

    .contact-link {
        font-size: 0.9rem; 
    }

    .contact-icon {
        width: 20px;
        height: 20px;
    }
}
